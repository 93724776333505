import { normalizeBlockContentNodes } from '@blocks-helpers'
import Footer from '@solid-ui-blocks/Footer/Block01'
import Header from '@solid-ui-blocks/Header/Block01'
import ModalWithTabs from '@solid-ui-blocks/Modal/Block01'
import ModalSimple from '@solid-ui-blocks/Modal/Block02'
import Seo from '@solid-ui-components/Seo'
import Layout from '@solid-ui-layout/Layout'
import { graphql } from 'gatsby'
import React from 'react'
import Helmet from 'react-helmet'

import './customStyleA.css'

import Slider from 'react-slick'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'

import { FaQuoteRight, FaRegStar, FaStar } from 'react-icons/fa'

import 'react-tabs/style/react-tabs.css'

import appStoreBadge from './assets/appStoreBadge.png'
import playStoreBadge from './assets/playStoreBadge.png'
import shutterstock_11 from './assets/shutterstock_11.jpg'
import shutterstock_6 from './assets/shutterstock_6.jpg'
import shutterstock_9 from './assets/shutterstock_9.jpg'
import userDummy1 from './assets/userDummy1.jpg'
import userDummy2 from './assets/userDummy2.jpg'
import userDummy3 from './assets/userDummy3.jpg'
import userDummy4 from './assets/userDummy4.jpg'

import FeatureTabTwo from '@solid-ui-blocks/FeaturesWithPhoto/Block01'
import FeatureTabOne from '@solid-ui-blocks/FeaturesWithPhoto/Block05'
import FeatureTabThree from '@solid-ui-blocks/FeaturesWithPhoto/Block07'
import Tabs from '@solid-ui-components/Tabs'
import Testimonials from '../Testimonials'

const ServiceAPage = props => {
  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)

  const settings = {
    slidesToShow: 1,
    infinite: false,
    slidesToScroll: 1,
    autoplay: true,
    dots: true,
    arrows: false,
    speed: 2000
  }

  const MyHTMLComponent = () => {
    return (
      <div className='service_spacer'>
        <div class='product1'>
          <div class='mycontainer'>
            <div class='sec1'>
              <h1>Floki D2C App</h1>
              <p>
                Embrace the power of Direct-to-Consumer e-commerce to take
                control of your sales and foster a direct relationship with your
                customers, transforming your wholesale distribution business.
              </p>
            </div>
          </div>
        </div>
        <div class='discrptn'>
          <div class='mycontainer'>
            <p>
              Introducing the Floki Direct to Consumer E-commerce App (D2C App)
              - Your ultimate white-label e-commerce solution for going
              direct-to-consumer! This app enables manufacturers and producers
              to sell products directly to consumers, fostering direct
              relationships and expanding customer base. With customizable
              features, efficient inventory management, integrated CRM, seamless
              payment integration, flexible shipping options, advanced
              analytics, and responsive design, our e-commerce solution empowers
              businesses to establish a strong online presence and leverage
              cutting-edge technology. Direct-to-consumer (D2C) has become
              increasingly popular, offering benefits like better branding
              control, improved customer relationships, and pricing management.
              By eliminating intermediaries, businesses can provide competitive
              pricing, personalized experiences, and valuable consumer behavior
              insights. Enjoy faster product launches, agile operations, and
              adaptability to changing market demands. Consumers today value
              convenience, transparency, and authentic connections with brands,
              making D2C a strategic approach for thriving in the e-commerce
              landscape. Embrace the D2C revolution and take your business to
              new heights
            </p>
          </div>
        </div>
        <div class='product1'>
          <div class='mycontainer'>
            <div class='sect'>
              <div class='row'>
                <div class='colm6'>
                  <img
                    className='img1'
                    src={shutterstock_6}
                    alt='product_img'
                  />
                  {/* <img src="./images/shutterstock_6.jpg" alt="product_img"> */}
                </div>
                <div class='colm6'>
                  <h4>Key Features :</h4>
                  <ul class='liststyle'>
                    <li>
                      Experience a <b>user-friendly, rebrandable</b> Direct to
                      Consumer e-commerce interface (D2C App)
                    </li>
                    <li>
                      Optimize offerings using built-in{' '}
                      <b>reporting and analytics tools</b> in Floki e-commerce
                      solution
                    </li>
                    <li>
                      Leverage <b>advanced marketing tools</b> for targeted
                      campaigns on Floki D2C app
                    </li>
                    <li>
                      Reach global audience with{' '}
                      <b>multi-language and currency</b> support D2C app
                    </li>
                    <li>
                      Enhance customer experience with real-time{' '}
                      <b>order tracking and delivery notifications </b>{' '}
                    </li>
                    <li>
                      Effortlessly manage your operations by <b>integrating</b>
                      major payment gateways, accounting software, and CRMs into
                      Floki Direct to Consumer E-commerce App (D2C App)
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class='sect'>
              <div class='row'>
                <div class='colm6'>
                  <h4>Benefits :</h4>
                  <ul class='liststyle'>
                    <li>
                      Establish a strong direct-to-consumer strategy to take
                      control of sales and distribution.
                    </li>
                    <li>
                      Build a direct relationship with customers, gain insights,
                      and tailor offerings with a direct-to-consumer e-commerce
                      platform.
                    </li>
                    <li>
                      Increase revenue by reducing costs of third-party sales
                      channels and distributors.
                    </li>
                    <li>
                      Enhance brand value and customer loyalty with a seamless
                      and personalized online shopping experience.by building
                      your own e-commerce solution.
                    </li>
                  </ul>
                </div>
                <div class='colm6'>
                  <img src={shutterstock_11} alt='product_img' />
                  {/* <img src="./images/shutterstock_11.jpg" alt="product_img"> */}
                </div>
              </div>
            </div>
            <div class='sect'>
              <div class='row'>
                <div class='colm6'>
                  <img src={shutterstock_9} alt='product_img' />
                  {/* <img src="./images/shutterstock_9.jpg" alt="product_img"> */}
                </div>
                <div class='colm6'>
                  <h4>Use Cases :</h4>
                  <ul class='liststyle'>
                    <li>
                      A product manufacturer who wants to take control of their
                      sales and distribution channels.
                    </li>
                    <li>
                      A producer who wants to build a direct relationship with
                      their customers and increase their revenue.
                    </li>
                    <li>
                      A business owner who wants to enhance their brand value
                      and increase customer loyalty by offering a seamless and
                      personalized online shopping experience.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const MySliderComponent = () => {
    return (
      <div className='container'>
        <div class='col_wrap'>
          <div class='col-_gap'>
            <div class='testimonial_slider'>
              <Slider {...settings}>
                <div class='item'>
                  <div class='ctnt'>
                    <figure>
                      <img src={userDummy1} alt='product_img' />
                    </figure>
                    <div class='right_ctnt'>
                      <h4>Jihad Hassan</h4>
                      <h6>Managing Director of Lifco</h6>
                      <p>
                        I was looking for a solution that would allow me to
                        create an online marketplace for our multiple stores and
                        lines of business, and the Floki Marketplace Platform
                        was the perfect fit. The setup was easy, and the
                        platform is customizable and scalable to meet my needs.
                      </p>
                      <ul>
                        <li>
                          <FaStar />
                        </li>
                        <li>
                          <FaStar />
                        </li>
                        <li>
                          <FaStar />
                        </li>
                        <li>
                          <FaStar />
                        </li>
                        <li>
                          <FaRegStar />
                        </li>
                      </ul>
                      <span>
                        <FaQuoteRight />
                      </span>
                    </div>
                  </div>
                </div>
                <div class='item'>
                  <div class='ctnt'>
                    <figure>
                      <img src={userDummy2} alt='product_img' />
                    </figure>
                    <div class='right_ctnt'>
                      <h4>Maysoon Homidan</h4>
                      <h6>ERP Project Manager at Addoha Poultry Company</h6>
                      <p>
                        Your needs will be fulfilled with them! Floki D2C is an
                        easy-to-use, customizable, and secure platform. The
                        platform is a great gateway to reach new consumers,
                        control your sales, and increase your distribution
                        channels. The Floki Solutions team is great at providing
                        the necessary support and responding promptly.
                      </p>
                      <ul>
                        <li>
                          <FaStar />
                        </li>
                        <li>
                          <FaStar />
                        </li>
                        <li>
                          <FaStar />
                        </li>
                        <li>
                          <FaStar />
                        </li>
                        <li>
                          <FaRegStar />
                        </li>
                      </ul>
                      <span>
                        <FaQuoteRight />
                      </span>
                    </div>
                  </div>
                </div>
                <div class='item'>
                  <div class='ctnt'>
                    <figure>
                      <img src={userDummy3} alt='product_img' />
                    </figure>
                    <div class='right_ctnt'>
                      <h4>Joby Varghese</h4>
                      <h6>Business Unit Manager</h6>
                      <p>
                        The white label e-commerce solution has helped us to
                        expand our reach and increase our revenue. Our customers
                        love the convenience of online shopping, and we've been
                        able to streamline our operations and reduce our costs.
                      </p>
                      <ul>
                        <li>
                          <FaStar />
                        </li>
                        <li>
                          <FaStar />
                        </li>
                        <li>
                          <FaStar />
                        </li>
                        <li>
                          <FaStar />
                        </li>
                        <li>
                          <FaRegStar />
                        </li>
                      </ul>
                      <span>
                        <FaQuoteRight />
                      </span>
                    </div>
                  </div>
                </div>
                <div class='item'>
                  <div class='ctnt'>
                    <figure>
                      <img src={userDummy4} alt='product_img' />
                    </figure>
                    <div class='right_ctnt'>
                      <h4>Mohamad Fleifel</h4>
                      <h6>CEO of MAB Trading</h6>
                      <p>
                        Our ordering process has become much more efficient
                        since we started using this white label e-commerce
                        solution. Our customers appreciate the convenience and
                        we've been able to grow our business as a result.
                      </p>
                      <ul>
                        <li>
                          <FaStar />
                        </li>
                        <li>
                          <FaStar />
                        </li>
                        <li>
                          <FaStar />
                        </li>
                        <li>
                          <FaStar />
                        </li>
                        <li>
                          <FaRegStar />
                        </li>
                      </ul>
                      <span>
                        <FaQuoteRight />
                      </span>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
          </div>

          <div class='col-_gap'>
            <div class='start_ctnt'>
              <h2>
                <span>Start Learning</span> and Join Our happy Users
              </h2>
              <p>
                With +30,000 SKUs available in our database,your time to go live
                should be minimal.We save you the effort of creating your
                database from scratch.
              </p>
              <div class='payroll-btn'>
                <img src={playStoreBadge} alt='product_img' class='mr-img' />
                <img src={appStoreBadge} alt='product_img' />
                {/* <a href="#"><FaGooglePlay /></a>
                    <a href="#"><FaAppStore /></a> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <Layout {...props}>
      <Seo title='Home' />

      <Helmet>
        <title>
          Direct to consumer eCommerce | eCommerce Solution | Floki Solutions
        </title>
        <meta
          name='google-site-verification'
          content='jvulVjeMbr8Qb07aPAbFxWgBuwOl_Q2A9EvJoemO1co'
        />
        <meta
          name='description'
          content="Unlock the potential of direct-to-consumer eCommerce (D2C) with our eCommerce app development services. We provide customizable solutions to expand your brand's reach."
        />
        <meta
          name='keywords'
          content='e-commerce solution, app for grocery delivery, ecommerce app development, d2c app, Direct to consumer e-commerce'
        />

        <meta
          property='og:title'
          content='Direct to consumer eCommerce | eCommerce Solution | Floki Solutions'
        />
        <meta
          property='og:description'
          content="Unlock the potential of direct-to-consumer eCommerce (D2C) with our eCommerce app development services. We provide customizable solutions to expand your brand's reach."
        />

        <meta
          property='og:url'
          content='https://flokisolutionsmain.gatsbyjs.io/floki-d2c-app/'
        />
        <meta property='og:type' content='website' />
      </Helmet>

      {/* Modals */}
      <ModalWithTabs content={content['authentication']} reverse />
      <ModalWithTabs content={content['contact']} />
      <ModalSimple content={content['advertisement']} />
      {/* Blocks */}

      <Header content={content['header']} />
      <MyHTMLComponent></MyHTMLComponent>
      <Tabs space={4} variant='pill'>
        <FeatureTabOne content={content['feature-tab-one']} />
        <FeatureTabTwo content={content['feature-tab-two']} reverse />
        <FeatureTabThree content={content['feature-tab-three']} />
      </Tabs>

      <Testimonials />

      {/* <Divider space='5' /> */}
      <Footer content={content['footer']} />
    </Layout>
  )
}

export const query = graphql`
  query ServiceAPageContent {
    allBlockContent(
      filter: { page: { in: ["site/floki-d2c-app", "shared"] } }
    ) {
      nodes {
        ...BlockContent
      }
    }
  }
`

export default ServiceAPage
